<template>
  <v-row justify="center">
    <v-dialog v-model="show" max-width="500" persistent>
      <v-card>
        <v-card-title >{{$t('package_current.confirm_tax')}}<v-spacer></v-spacer>
          <!-- <v-icon :color="color.theme" dense dark size="35">delete</v-icon> -->
        </v-card-title>
        <v-divider></v-divider>
          <v-card-text  class="pa-1 pt-1 pb-1 text-center" >
            <p class="pl-4 ma-2">{{$t('package_current.want_tax')}}</p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" outlined @click="$emit('close')">{{$t('package_current.cancel')}}</v-btn>
          <v-btn color="red" outlined @click="$emit('closedialog')">{{$t('package_current.unwish')}}</v-btn>
          <v-btn :color="color.theme" :dark="color.darkTheme" @click="$emit('payment')">{{$t('package_current.wish')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
export default {
  props: ["show"],
  computed:{
  ...mapState(["username", "authorize", "account_active", "color","role_level"]),
  ...mapState({ processloader: "loading" }),
  ...mapGetters([
    "dataUsername",
    "dataAuthorize",
    "dataAccountActive",
    "dataAccesstoken",
    "dataBusinessProfile",
    "dataCitizenProfile",
    "dataDepartmentAccessId",
    "dataAccountId",
  ]),
  }
};
</script>

<style>
</style>